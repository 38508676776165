import React, { useState } from 'react';
import {
  IonPage, IonContent, IonButton, IonIcon, IonLoading,
} from '@ionic/react';
import {
  logoApple, logoFacebook, chatbubble
} from 'ionicons/icons';
import './MaleRegister.scss';

type Props = {
  history: any,
}

const MaleRegister: React.FC<Props> = ({ history }) => {
  const [isLoading, setIsLoading] = useState(false);

  const getIn = () => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      history.push('/tabs/me');
    }, 1000); // dummy loader for Loggin In
  }

  return (
    <IonPage>
      <IonContent forceOverscroll={ false } className="landing-page bg-gradient">
        <IonLoading
          isOpen={ isLoading }
          message={ 'Logging in...' }
          duration={ 5000 }
        />

        <div className="vertical-layout safe-area-bottom">
          <div className="section-brand">
            <div className="logo">
              <img className="logo-icon" src="assets/img/logo_small_w.png" alt="" />
              <span className="logo-text">
                EATAPIA
              </span>
            </div>
          </div>

          <div className="section-buttons ion-padding">
            <div className="ion-padding">
              A series of steps to verify the identity
            </div>

            <IonButton expand="block" fill="outline" color="white" shape="round" className="button-facebook button-landing" onClick={ getIn }>
              Approve
            </IonButton>

          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

MaleRegister.defaultProps = {

}

export default MaleRegister;
