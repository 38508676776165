export default [
  {
    id: 0,
    isSender: true,
    type: 'image',// text || image
    body: 'https://media.giphy.com/media/1lk1IcVgqPLkA/giphy.gif',
    timestamp: 'Mar 30, 2021 9:47 AM'
  },
  {
    id: 1,
    isSender: false,
    avatar: 'assets/img/avatars/hieu.png',
    type: 'text',// text || image
    body: 'Hey yo what\'s up?',
    timestamp: 'Mar 30, 2021 9:48 AM'
  },
  {
    id: 2,
    isSender: true,
    type: 'image',// text || image
    body: 'https://media.giphy.com/media/1g0Nz8xkYaqiBBYyLe/giphy.gif',
    timestamp: 'Mar 30, 2021 9:50 AM'
  },
  {
    id: 3,
    isSender: false,
    avatar: 'assets/img/avatars/hieu.png',
    type: 'image',// text || image
    body: 'https://media.giphy.com/media/4BWByhavc3Hr2/giphy.gif',
    timestamp: 'Mar 30, 2021 9:52 AM'
  },
  {
    id: 4,
    isSender: true,
    type: 'text',// text || image
    body: 'Where are you, buddy?',
    timestamp: 'Mar 30, 2021 9:53 AM'
  },
  {
    id: 5,
    isSender: false,
    avatar: 'assets/img/avatars/hieu.png',
    type: 'text',// text || image
    body: 'I\'m almost there',
    timestamp: 'Mar 30, 2021 9:53 AM'
  }
];
