import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  setupIonicReact,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Landing from './pages/Landing/Landing';
import Tabs from './pages/Tabs/Tabs';
import Chat from './pages/Chat/Chat';
// import ThemeService from './services/theme.service';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

// custom CSS
import './sass/variables.override.scss';
import './sass/helper.scss';
import './sass/app.scss';
import './sass/dark.scss';
import Registration from './pages/Registration/Registration';
import MaleRegister from './pages/MaleRegister/MaleRegister';

// force the theme to iOS mode
setupIonicReact({
  mode: 'ios',
  backButtonText: '',
  swipeBackEnabled: false,
});

const App: React.FC = () => {
  useEffect(() => {
    // Restore Dark Mode preference from LocalStorage
    // ThemeService.restore();
  })

  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route path="/tabs" component={Tabs} />
          <Route path="/landing" component={Landing} exact/>
          <Route path="/chat" component={Chat} exact/>
          <Route path="/register" component={Registration} exact/>
          <Route path="/maleregister" component={MaleRegister} exact/>
          <Route path="/" render={() => <Redirect to="/landing" />} exact />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  )
};

export default App;
